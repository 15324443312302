import FaceBook from "../../assets/logos_facebook.svg"
import Instagram from "../../assets/Instagram_logo_2016 1.svg"
import Twitter from "../../assets/logos_twitter.svg"
const Headerdata = [
    {
      id: 1,
      image: FaceBook,
    
    },
    {
      id: 2,
      image: Twitter,
    
    },
    {
      id: 3,
      image: Instagram,
    
    },

  ]
  export default Headerdata