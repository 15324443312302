import React from 'react'
import ServicesStyles from './Services.module.css'
import ServiceData from './Data/Services'
const ss = ServicesStyles
const Services = () => {
  return (
    <div id="services" className={ss.services_container}>
      <h3 className="pb-10 ">Our Services</h3>
      <div className="pl-2 gap-8 lg:pl-16 grid grid-cols-2 gap-18 sm:grid-cols-3 justify-center sm:gap-18 sm:justify-between items-center sm:px-22 px-22 pr-22">
        {ServiceData.map((services: any) => {
          return (
            <div
              className="min-w-[130px]  justify-between flex flex-col sm:justify-between gap-4 sm:max-w-[400px]"
              key={services.id}
            >
              <img
                src={services.image}
                className="object-contain"
                alt="service-image"
              />

              <p
                className="text-[10px] sm:text-base text-center
              "
              >
                {services.text}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Services
